// @mui
import {
  Badge,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Drawer,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { savePlaybook } from '../../api/marketplace/playbookSlice';
import { selectUser } from '../../api/user/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { API } from 'aws-amplify';
import { apiPostAndRedirect } from '../../utils/api';
import Iconify from '../Iconify/Iconify';
// component

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme, open }: any) => ({
  zIndex: 999,
  right: open ? 460 : 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  //boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export default function CartWidget({
  cartCount,
  cart,
}: {
  cartCount: number;
  cart: any[];
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  return (
    <StyledRoot>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor='right'
        PaperProps={{
          sx: { width: 460, backgroundColor: 'lightgray' },
        }}>
        <Container sx={{ p: 4 }}>
          <Typography variant='h4'>Account Cart</Typography>
          <Stack spacing={2}>
            {cart.map((element) => {
              return (
                <Item key={element.meta.uid}>
                  <Typography variant='h6'>{element.meta.name}</Typography>
                  <Typography variant='body2'>{element.meta.description}</Typography>
                  <Typography>
                    {element.price.price} {element.price.currency}
                  </Typography>
                </Item>
              );
            })}
          </Stack>
          <Divider sx={{ p: 2 }} variant='fullWidth' />
          {cart.length && (
            <Typography variant='h4'>
              {'Total payout: '}
              {cart.reduce((acc, e) => (acc += e.price.price), 0)}{' '}
              {cart[0].price.currency}
            </Typography>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant='contained'
            onClick={() => {
              dispatch(savePlaybook(cart));
              apiPostAndRedirect('create-checkout-session', {
                customer_id: user.payment_customer_id,
                price_list: cart.map(element => { return { price: element.price.payment_price_id, quantity: 1 } })
                });
            }}>
            Check out
          </Button>
        </Container>
      </Drawer>
      <Badge
        showZero
        badgeContent={cartCount}
        color='error'
        max={99}
        onClick={() => {
          setOpen(true);
        }}>
        <Iconify icon='eva:shopping-cart-fill' width={24} height={24} />
      </Badge>
    </StyledRoot>
  );
}
