import PropTypes from 'prop-types';
// material
import { AppBar, Box, Button, IconButton, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import LogoSVG from '../../assets/images/logo/Vizla-icon-light.svg';
// import LanguagePopover from '../Language/Language';
// import NotificationsPopover from '../Notifications/NotificationsPopover';
// import LogoSVG from '@assets/images/Logo.svg'
import { grey } from '@mui/material/colors';
import AccountPopover from '../Account/AccountPopover';
// ----------------------------------------------------------------------
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../api/user/userSlice';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: '3',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: 'rgb(27, 35, 52)', //alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    // minHeight: APPBAR_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }: any) {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);

  const goto = (page: string) => {
    navigate(`/${user['custom:domain_name']}/${page}`);
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          // onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'flex' } }}>
          <img src={LogoSVG} alt='photoURL' width='50px' color={'white'} />
        </IconButton>
        <Stack
          direction='row'
          alignItems='center'
          spacing={{ xs: 0.5, sm: 1.5 }}>
          <Button sx={{ color: grey[300] }} onClick={() => goto('/')}> SUBSCRIPTIONS</Button>
          {/* <Button sx={{ color: grey[300] }} onClick={() => goto('marketplace')}>MARKETPLACE</Button>
          <Button sx={{ color: grey[300] }} onClick={() => goto('postman')}>**POSTMAN**</Button>
          <Button sx={{ color: grey[300] }} onClick={() => goto('playbooks')}>My Playbooks</Button>
          <Button sx={{ color: grey[300] }}>PLAYBOOKS</Button>
          <Button sx={{ color: grey[300] }}>SCHEDULE</Button>
          <Button sx={{ color: grey[300] }}>REPORTS</Button> */}
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction='row'
          alignItems='center'
          spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle >
  );
}
