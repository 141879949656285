// import { LoadingButton, LoadingButtonProps } from '@mui/lab';
// import { makeStyles } from '@mui/styles';

// interface Props {
//   color: 'red' | 'blue';
// }

// const useStyles = makeStyles({
//   root: {
//     background: (props: Props) =>
//       props.color === 'red'
//         ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
//         : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
//     border: 0,
//     borderRadius: 3,
//     boxShadow: (props: Props) =>
//       props.color === 'red'
//         ? '0 3px 5px 2px rgba(255, 105, 135, .3)'
//         : '0 3px 5px 2px rgba(33, 203, 243, .3)',
//     color: 'white',
//     height: 48,
//     padding: '0 30px',
//     // margin: 8,
//   },
// });

// export const StyledLoadingButton = (
//   props: Props & Omit<LoadingButtonProps, keyof Props>
// ) => {
//   const { color, ...other } = props;
//   const classes = useStyles(props);
//   return <LoadingButton className={classes.root} {...other} />;
// };

import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { makeStyles } from 'tss-react/mui';

interface Props {
  color: 'red' | 'blue';
}
type StyleProps = 'red' | 'blue';

const useStyles = makeStyles<{ color: StyleProps }>()((theme, { color }) => ({
  root: {
    background:
      color === 'red'
        ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
        : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow:
      color === 'red'
        ? '0 3px 5px 2px rgba(255, 105, 135, .3)'
        : '0 3px 5px 2px rgba(33, 203, 243, .3)',
    color: 'white',
    height: 48,
    // padding: '0 30px',
    // margin: 8,
  },
}));

export const StyledLoadingButton = (
  props: Props & Omit<LoadingButtonProps, keyof Props>
) => {
  const { className, color, ...other } = props;
  //   const [color, setColor] = useState<StyleProps>('red');

  const { classes, cx } = useStyles({ color });
  //   const { color, ...other } = props;
  //   const classes = useStyles(props);
  return <LoadingButton className={cx(classes.root, className)} {...other} />;
};
