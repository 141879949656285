import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import authReducer from '../api/auth/authSlice';
import companyReducer from '../api/company/companySlice';
import userReducer from '../api/user/userSlice';
import playbookReducer from '../api/marketplace/playbookSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    company: companyReducer,
    user: userReducer,
    playbook: playbookReducer
  },
  middleware: [thunkMiddleware],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
