import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './app/store';
import { Account } from './components/Authentication/Accounts';
import { App } from './pages/App';

const root = createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Account>
        <App />
      </Account>
    </BrowserRouter>
  </Provider>
);
