// @mui
import { Container, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import MyPlaybooksList from '../../components/Marketplace/MyPlaybooksList';

export default function MyPlaybooksPage() {
  const [playbooks, setPlaybooks] = useState([]);

  useEffect(() => {
    API.get(`playbooks`, `/playbooks`, {}).then((resp) => {
      console.log('🚀 ~ file: Postman.tsx:27 ~ API.get ~ resp', resp);
      setPlaybooks(resp.body);
    });
  }, []);

  return (
    <>
      <Container>
        <Typography variant='h4' sx={{ mb: 5 }}>
          My Playbooks
        </Typography>

        <Container sx={{ p: 4 }}>
          {playbooks && <MyPlaybooksList products={playbooks} />}
        </Container>
      </Container>
    </>
  );
}
