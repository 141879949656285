import Iconify from '../Iconify/Iconify';

const getIcon = (name: any) => <Iconify icon={name} width={22} height={22} />;

const navConfig = (domain: string) => [
  {
    title: 'home',
    path: `${domain}/`,
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'marketplace',
    path: `${domain}/marketplace`,
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'chatter',
    path: `${domain}/chatter`,
    icon: getIcon('eva:hash-fill'),
  },
  {
    title: 'users',
    path: `${domain}/users`,
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'billing',
    path: `${domain}/billing`,
    icon: getIcon('eva:credit-card-fill'),
  },
];

export const navBottomConfig = [
  {
    title: 'account',
    path: '/account',
    icon: getIcon('eva:grid-fill'),
  },
  {
    title: 'terms of service',
    path: '/tos',
    icon: getIcon('eva:bookmark-fill'),
  },
];

export default navConfig;
