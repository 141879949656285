import { styled } from '@mui/material/styles';

export const FormStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    margin: theme.spacing(0, 2, 2, 2),
    width: '92vw',
  },
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 2, 2, 2),
    width: '96vw',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '600px',
    width: '100%',
    margin: theme.spacing(2, 4, 2, 2),
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '600px',
    width: '100%',
    margin: theme.spacing(2, 4, 2, 2),
  },
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
