import { styled } from '@mui/material/styles';

export const StartupPageStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  justifyContent: 'space-evenly',
  height: '100vh',
  backgroundColor: 'lightgrey',
}));
