// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { selectPlaybook } from '../../api/marketplace/playbookSlice';
import { useAppSelector } from '../../app/hooks';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export default function MarketplacePage() {
  const cart: any[] = useAppSelector(selectPlaybook);
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Typography variant='h4' sx={{ mb: 5 }}>
          Checkout
        </Typography>

        <Container sx={{ p: 4 }}>
          <Stack spacing={2}>
            {cart.map((element) => {
              return (
                <Item key={element.uid}>
                  <Typography variant='h6'>{element.name}</Typography>
                  <Typography variant='body2'>{element.description}</Typography>
                  <Typography>
                    {element.pricing.price} {element.pricing.currency}
                  </Typography>
                </Item>
              );
            })}
          </Stack>
          <Divider sx={{ p: 2 }} variant='fullWidth' />
          {cart.length && (
            <Typography variant='h4'>
              {'Total payout: '}
              {cart.reduce((acc, e) => (acc += e.pricing.price), 0)}{' '}
              {cart[0].pricing.currency}
            </Typography>
          )}
          <Box sx={{ flexGrow: 2 }} />
          <Stack direction='row' spacing={1}>
            <Button variant='contained' onClick={() => {}}>
              Purchase Cart
            </Button>
            <Button variant='outlined' onClick={() => navigate(-1)}>
              Go back
            </Button>
          </Stack>
        </Container>
      </Container>
    </>
  );
}
