import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from '../PageCover/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function UnderMaintenance() {
  return (
    <Page title='Page is under maintenance'>
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant='h3' paragraph>
            Sorry, page is under maintenance!
          </Typography>

          <Typography sx={{ color: 'text.secondary', p: 4 }}>
            Page is not ready!
          </Typography>

          <Button
            to='/'
            size='large'
            variant='contained'
            component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
