// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/PageCover/Page';

import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { selectCompany } from '../../api/company/companySlice';
import { useAppSelector } from '../../app/hooks';
import GenerateLicenseFile from '../../components/GenerateFile/GenerateLicenseFile';
import { LicenseList } from '../../components/LicenseList/LicenseList';
// import PurchasePlaybook from '../../components/Playbooks/PurchasePlaybook';

export const DashboardPage = () => {
  const company = useAppSelector(selectCompany);
  const [licenses, setLicenses] = useState([]);
  const [application, setApplication]: any = useState();
  const [isUpdated, setIsUpdated] = useState(false);

  const [open, setOpen] = useState(false);
  const [currentLicense, setCurrentLicense]: any = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (company) {
      API.post('onboaringApi', `/mac`, { body: company }).then((resp) => {
        setIsUpdated(false);
        const found = resp.Items.find((item: any) => item.mac === 'vizla-app');
        if (found) {
          setApplication(found);
        }
        setLicenses(resp.Items.filter((item: any) => item.mac !== 'vizla-app'));
      });
    }
  }, [company, isUpdated]);
  return (
    <Page
      title='Dashboard'
      sx={{ height: '100%', display: 'flex', alignItems: 'top' }}>
      <Container sx={{ width: '100vw', height: '100vh' }}>
        <Stack direction='row'>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant='h4'
              sx={{ textAlign: 'center', width: '20%', p: 2 }}>
              Need Another License?
            </Typography>
            <Typography
              variant='h6'
              noWrap={false}
              paragraph={true}
              sx={{ width: '65%', p: 2 }}>
              Enter the MAC address displayed in your installation of Vizla to
              generate a license file. If a license for your instance already
              exists, your previous license will be overwritten.
            </Typography>
            {/* <Box sx={{ width: '15%' }}>
              <GenerateLicenseFile
                setIsUpdated={setIsUpdated}
                licenses={licenses}
              />
            </Box> */}
          </Box>
        </Stack>
        <Typography variant='h5' sx={{ mb: 5, pt: 5 }}>
          Create new License
        </Typography>
        <GenerateLicenseFile setIsUpdated={setIsUpdated} licenses={licenses} />
        {/* <PurchasePlaybook></PurchasePlaybook> */}

        <Typography variant='h6' sx={{ mb: 5, pt: 5 }}>
          Your Current Subscriptions
        </Typography>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>New License</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              Are you sure you want to override existing license file?
            </DialogContentText>
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleClose();
                setCurrentLicense(null);
              }}
              key={currentLicense?.key}
              href={currentLicense?.location}
              download={currentLicense?.key}>
              Download
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ p: 1 }} />

        <LicenseList
          licenses={licenses}
          handleClickOpen={handleClickOpen}
          setCurrentLicense={setCurrentLicense}
        />
      </Container>
    </Page>
  );
};
