import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { poolData } from './Model/UserPool';

const cognito = new AWS.CognitoIdentityServiceProvider({ region: 'us-east-2' });
const AuthContext = createContext({});

const Account = (props: any) => {
  const navigate = useNavigate();

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = poolData().getCurrentUser();
      if (user) {
        user.getSession(async (err: any, session: any) => {
          if (err) {
            reject();
          } else {
            const attributes: any = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results: Record<string, unknown> = {};

                  for (let attribute of attributes!) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            const accessToken = session.accessToken.jwtToken;

            const mfaEnabled = await new Promise((resolve) => {
              cognito.getUser(
                {
                  AccessToken: accessToken,
                },
                (err, data) => {
                  if (err) resolve(false);
                  else
                    resolve(
                      data.UserMFASettingList &&
                        data.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA')
                    );
                }
              );
            });

            const token = session.getIdToken().getJwtToken();

            resolve({
              user,
              accessToken,
              mfaEnabled,
              headers: {
                'x-api-key': attributes['custom:apikey'],
                Authorization: token,
              },
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
    });

  const authenticate = async (Username: any, Password: any) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool: poolData() });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log('onSuccess:', data);
          resolve(data);
        },

        onFailure: (err) => {
          console.error('onFailure:', err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          console.log('newPasswordRequired:', data);
          resolve(data);
        },
        mfaRequired: function (codeDeliveryDetails) {
          var verificationCode: any = prompt(
            'Please enter verification code from sms',
            ''
          );
          user.sendMFACode(verificationCode, this);
        },

        totpRequired: () => {
          const token = prompt('Please enter your 6-digit token');
          user.sendMFACode(
            token as string,
            {
              onSuccess: () => (window.location.href = window.location.href),
              onFailure: () => alert('Incorrect code!'),
            },
            'SOFTWARE_TOKEN_MFA'
          );
        },
      });
    });

  const logout = () => {
    const user = poolData().getCurrentUser();
    if (user) {
      user.signOut();
      navigate('/signin', { replace: true });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { Account, AuthContext as AccountContext };
