import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { Avatar, Box, Drawer, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Avatarka from '../../assets/images/avatar_default.jpg';
import NavSection from './NavSection';

import LogoSVG from '../../assets/images/logo/vizla-logo-light-side.svg';
import { Scrollbar } from '../Scrollbar/ScrollBar';
import navConfig, { navBottomConfig } from './NavConfig';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    // flexShrink: 0,
    // width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  domain: PropTypes.string,
};

export default function DashboardSidebar({
  domain,
  isOpenSidebar,
  onCloseSidebar,
}: any) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,

        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}>
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: 'inline-flex',
          justifyContent: 'center',
        }}>
        <img src={LogoSVG} alt='photoURL' width='100px' />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline='none' component={RouterLink} to='#'>
          <AccountStyle>
            <Avatar src={Avatarka} alt='photoURL' />
            <Box sx={{ ml: 2 }}>
              <Typography variant='subtitle2' sx={{ color: 'lightgrey' }}>
                Lev {/* {account.displayName} */}
              </Typography>
              <Typography variant='body2' sx={{ color: 'darkgrey' }}>
                Account Administrator {/* {account.role} */}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig(domain)} />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}>
        <NavSection navConfig={navBottomConfig} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            py: 1,
          }}>
          <Typography variant='body2' color='gray'>
            VIZLA
          </Typography>
          <Box sx={{ p: 1 }} />
          <Typography variant='caption' color='darkgrey'>
            version 1.1
          </Typography>
        </Box>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Drawer
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { width: DRAWER_WIDTH, backgroundColor: 'rgb(39, 64, 98)' },
        }}>
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
