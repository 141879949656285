// @mui
import { Box, Container, Stack, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import ProductCartWidget from '../../components/Marketplace/ProductCartWidget';
import ProductFilterSidebar from '../../components/Marketplace/ProductFilterSidebar';
import ProductList from '../../components/Marketplace/ProductList';
import ProductSort from '../../components/Marketplace/ProductSort';

export default function MarketplacePage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [playbooks, setPlaybooks] = useState([]);
  const [cart, setCart] = useState([]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  useEffect(() => {
    API.get(`playbooks`, `/playbooks`, {}).then((resp) => {
      console.log('🚀 ~ file: Postman.tsx:27 ~ API.get ~ resp', resp);
      setPlaybooks(resp.body);
    });
  }, []);

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  return (
    <>
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Container>

          <Typography variant='h4' sx={{}}>
            Playbooks
          </Typography>

          <Stack
            direction='row'
            flexWrap='wrap-reverse'
            alignItems='center'
            justifyContent='flex-end'
            sx={{ mb: 5 }}>
            <Stack direction='row' spacing={1} flexShrink={0} sx={{ my: 1 }}>
              <ProductFilterSidebar
                openFilter={openFilter}
                onOpenFilter={handleOpenFilter}
                onCloseFilter={handleCloseFilter}
              />
              <ProductSort />
            </Stack>
          </Stack>
          {playbooks && (
            <ProductList products={playbooks} cart={cart} setCart={setCart} />
          )}
        </Container>

        <ProductCartWidget cartCount={cart.length} cart={cart} />
      </Box>
    </>
  );
}
