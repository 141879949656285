import { LoadingButton } from '@mui/lab';
import { Alert, Stack, TextField } from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { API } from 'aws-amplify';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { poolData } from './Model/UserPool';

// ----------------------------------------------------------------------

export default function ConfirmForm({ username, user }: any) {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
  });

  var cognitoUser = new CognitoUser({
    Username: username,
    Pool: poolData(),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setLoading(true);
      cognitoUser.confirmRegistration(values.code, true, (err, result) => {
        if (err) {
          setError(err.message || JSON.stringify(err));
          return;
        }
        API.post('onboaringApi', `/`, {
          body: user,
        })
          .then((response) => {
            setLoading(false);
            navigate('/signin', { replace: true });
          })
          .catch((e) => toast.error(e.message));
      });
    },
  });

  let { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {error ? <Alert>{error}</Alert> : null}
          <TextField
            fullWidth
            autoComplete='code'
            type='code'
            label='Confirmation code'
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ my: 2 }}
        />

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={loading}>
          Confirm
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
