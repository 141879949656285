import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
// material
import {
  Divider,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { toast } from 'react-toastify';
import { StyledLoadingButton } from '../ContainerStyles/ButtonStyles/LoadingButtonStyle';
import { poolData } from './Model/UserPool';
// component
import 'react-phone-input-2/lib/style.css';

// ----------------------------------------------------------------------

export default function RegisterForm({
  setStage,
  setUsername,
  setPassword,
  serUser,
}: any) {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too short.')
      .max(50, 'Too long.')
      .required('First name is required.'),
    last_name: Yup.string()
      .min(2, 'Too short.')
      .max(50, 'Too long.')
      .required('Last name is required.'),
    phone_number: Yup.string()
      .required('Phone number is required.')
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
        'Phone number is not valid.'
      ),
    company_name: Yup.string().required('Company name is required.'),
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required.'),
    confirm_password: Yup.string()
      .required('Please confirm your password')
      .oneOf([Yup.ref('password'), null], "Passwords don't match."),
    domain_name: Yup.string()
      .required('Domain name is required.')
      .matches(
        /^(?!_|\d)/,
        'Domain should not start with numbers and underscores'
      )
      .matches(
        /[a-zA-Z0-9_]+$/,
        'Domain should contain letters, numbers and underscores'
      ),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone_number: '+',
      company_name: '',
      password: '',
      confirm_password: '',
      domain_name: '',
      email: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      if (values.password !== values.confirm_password) {
        toast.error("Passwords didn't match");
        return;
      }
      const { confirm_password, password, ...user } = values;
      setLoading(true);
      const attributeFirstName = new CognitoUserAttribute({
        Name: 'custom:first_name',
        Value: values.first_name,
      });
      const attributeLastName = new CognitoUserAttribute({
        Name: 'custom:last_name',
        Value: values.last_name,
      });
      const attributeCompanyName = new CognitoUserAttribute({
        Name: 'custom:company_name',
        Value: values.company_name,
      });
      const attributePhoneNumber = new CognitoUserAttribute({
        Name: 'phone_number',
        Value: values.phone_number,
      });
      const attributeDomainName = new CognitoUserAttribute({
        Name: 'custom:domain_name',
        Value: values.domain_name,
      });
      poolData().signUp(
        values.email,
        values.password,
        [
          attributeFirstName,
          attributeCompanyName,
          attributeLastName,
          attributePhoneNumber,
          attributeDomainName,
        ],
        [],
        (err, data) => {
          setLoading(false);
          if (err) {
            toast.error(err.message);
            return;
          }
          toast.success('Company registered.');
          setUsername(values.email);
          setPassword(values.password);
          serUser(user);
          setStage(2);
        }
      );
    },
  });

  let { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                autoComplete='first_name'
                type='text'
                label='First name'
                {...getFieldProps('first_name')}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                autoComplete='last_name'
                type='text'
                label='Last name'
                {...getFieldProps('last_name')}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            autoComplete='phone_number'
            type='text'
            label='Phone number'
            {...getFieldProps('phone_number')}
            error={Boolean(touched.phone_number && errors.phone_number)}
            helperText={touched.phone_number && errors.phone_number}
          />
          <TextField
            fullWidth
            autoComplete='company_name'
            type='text'
            label='Company name'
            {...getFieldProps('company_name')}
            error={Boolean(touched.company_name && errors.company_name)}
            helperText={touched.company_name && errors.company_name}
          />
          <TextField
            fullWidth
            autoComplete='username'
            type='email'
            label='Email address'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            label='Password'
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            label='Confirm Password'
            {...getFieldProps('confirm_password')}
            error={Boolean(touched.confirm_password && errors.confirm_password)}
            helperText={touched.confirm_password && errors.confirm_password}
          />
          <TextField
            fullWidth
            label='Portal'
            {...getFieldProps('domain_name')}
            InputProps={{
              startAdornment: (
                <>
                  <Typography fontStyle={{ color: 'grey' }} sx={{ pr: 1 }}>
                    https://www.assuredtek.co/{' '}
                  </Typography>
                  <Divider orientation='vertical' flexItem />
                </>
              ),
            }}
            error={Boolean(touched.domain_name && errors.domain_name)}
            helperText={touched.domain_name && errors.domain_name}
          />
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ my: 2 }}>
            <Typography variant='body2' sx={{ mt: { md: -2 } }}>
              Already have an account? {''}
              <Link variant='subtitle2' component={RouterLink} to='/signin'>
                Login
              </Link>
            </Typography>
          </Stack>

          <StyledLoadingButton
            fullWidth
            color='blue'
            size='large'
            type='submit'
            variant='contained'
            loading={loading}>
            Register
          </StyledLoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
