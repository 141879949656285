import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

MyPlaybooksList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function MyPlaybooksList({ products, ...other }: any) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product: any) => (
        <Grid key={product.meta.uid} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product} />
        </Grid>
      ))}
    </Grid>
  );
}
