import { Button, Card, Grid, Typography } from '@mui/material';

export const LicenseList = ({
  licenses,
  handleClickOpen,
  setCurrentLicense,
}: any) => {
  return (
    <Grid container spacing={2}>
      {licenses.map((license: any) => {
        return (
          <Grid item key={license.mac} xs={12} sm={6} md={3}>
            <Card sx={{ p: 3 }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                MAC: {license.mac}
              </Typography>
              <Typography>Created at: {license.createdAt}</Typography>
              <Typography>Expired at: {license.expiredAt}</Typography>
              <Button
                onClick={() => {
                  handleClickOpen();
                  setCurrentLicense(license);
                }}>
                Download License
              </Button>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
