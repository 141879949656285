import { Link, Stack, TextField, Typography } from '@mui/material';
import { API } from 'aws-amplify';
import { Form, FormikProvider, useFormik } from 'formik';
import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { StyledLoadingButton } from '../ContainerStyles/ButtonStyles/LoadingButtonStyle';
import { AccountContext } from './Accounts';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { authenticate }: any = useContext(AccountContext);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      API.get('onboaringApi', `/user/${values.email}`, {})
        .then((response) => {
          // check if /:domain matches user domain later (routing upgrade)

          if (!response.Item) {
            toast.warning(`User does not exist.`);
            return;
          }
          if (response.Item.status === 'rejected') {
            toast.warning(
              `Something went wrong. Please, contact administrator for more information.`
            );
            return;
          }
          if (response.Item.status !== 'created') {
            toast.warning(`Infrastructure is not ready...`);
            return;
          }
          authenticate(values.email, values.password)
            .then((data: any) => {
              const domain_name = data.idToken.payload['custom:domain_name'];
              navigate(`/${domain_name}`, { state: { domain_name } });
            })
            .catch((err: any) => {
              toast.error(err.message);
            });
        })
        .catch((e) => toast.error(e.message));
    },
  });

  let { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete='username'
            type='email'
            label='Email address'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            label='Password'
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ my: 2 }}>
          <Typography variant='body2' sx={{ mt: { md: -2 } }}>
            Create a new company {''}
            <Link variant='subtitle2' component={RouterLink} to='/signup'>
              Get started
            </Link>
          </Typography>
        </Stack>

        <StyledLoadingButton
          fullWidth
          color='blue'
          size='large'
          type='submit'
          variant='contained'
          loading={loading}>
          Login
        </StyledLoadingButton>
      </Form>
    </FormikProvider>
  );
}
