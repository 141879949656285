import { Typography } from '@mui/material';
import LoginForm from '../../components/Authentication/LoginForm';
import { FormStyle } from '../../components/ContainerStyles/StartupStyles/FormDivForm';
import { LogoPerspective } from '../../components/ContainerStyles/StartupStyles/LogoPerspective';
import { MainCardStyle } from '../../components/ContainerStyles/StartupStyles/MainCardStyle';
import { StartupPageStyle } from '../../components/ContainerStyles/StartupStyles/StartupPageStyle';

export const LoginPage = () => {
  return (
    <StartupPageStyle>
      <MainCardStyle>
        <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5 }}>
          Welcome to Vizla
        </Typography>
        <LogoPerspective />
        <Typography variant="h6">
          {window.location.origin.includes('vizladev') ? "DEVELOPMENT SYSTEM" : "Release 1.3.5 BUILD 280623"}
        </Typography>
      </MainCardStyle>

      <FormStyle>
        <Typography variant='h4' gutterBottom>
          Sign in to Vizla
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Enter your details below.
        </Typography>

        <LoginForm />
      </FormStyle>
    </StartupPageStyle>
  );
};
