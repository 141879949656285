import { Box, Link, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';
import useResponsive from '../../app/hooks';
import ConfirmForm from '../../components/Authentication/ConfirmForm';
import RegisterForm from '../../components/Authentication/RegisterForm';
import { FormStyle } from '../../components/ContainerStyles/StartupStyles/FormDivForm';
import { LogoPerspective } from '../../components/ContainerStyles/StartupStyles/LogoPerspective';
import { MainCardStyle } from '../../components/ContainerStyles/StartupStyles/MainCardStyle';
import { StartupPageStyle } from '../../components/ContainerStyles/StartupStyles/StartupPageStyle';

export const RegisterPage = () => {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const [modal, showModal] = useState(false);

  const [stage, setStage] = useState(1);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [user, serUser] = useState();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '90%',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  };

  return (
    <StartupPageStyle>
      {mdUp && (
        <MainCardStyle>
          <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5 }}>
            Register Your Organization
          </Typography>
          <Typography variant='h5' sx={{ px: 5, mt: 10, mb: 5 }}>
            Fill out the information to the right and click "Register" to begin
            the account onboarding process. You will receive an email when the
            process is complete.
          </Typography>
          <LogoPerspective />
        </MainCardStyle>
      )}

      <FormStyle>
        <Typography variant='h4' gutterBottom>
          Get started.
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Create your company with AssuredTek.
        </Typography>

        {stage === 1 ? (
          <RegisterForm
            setStage={setStage}
            setUsername={setUsername}
            setPassword={setPassword}
            serUser={serUser}
          />
        ) : (
          <ConfirmForm username={username} user={user} />
        )}

        <Typography
          variant='body2'
          align='center'
          sx={{ color: 'text.secondary', mt: 3 }}>
          By registering, I agree to Minimal&nbsp;
          <Link
            underline='always'
            color='text.primary'
            onClick={() => {
              showModal(true);
            }}>
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            underline='always'
            color='text.primary'
            target='_blank'
            rel='noopener noreferrer'
            href='https://assuredtek.com/privacy-policy/'>
            Privacy Policy
          </Link>
          .
        </Typography>

        {!smUp && (
          <Typography variant='body2' sx={{ mt: 3, textAlign: 'center' }}>
            Already have an account?{' '}
            <Link variant='subtitle2' to='/signin' component={RouterLink}>
              Login
            </Link>
          </Typography>
        )}
      </FormStyle>
      <Modal
        open={modal}
        onClose={() => {
          showModal(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <Box
            style={{
              overflowX: 'hidden',
              overflowY: 'scroll',
              height: '95%',
            }}>
            <ReactMarkdown remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]}>
              {`## End User License Agreement (EULA)

The End User License Agreement (EULA) can be found on our company website and is a legal agreement between you (“Licensee” or “you”) and AssuredTek under which AssuredTek grants licenses regarding computer software, associated media, printed materials, and online or electronic documentation. AssuredTek licenses use of the Software and Documentation to you based on this EULA. AssuredTek does not sell the Software or Documentation to you. AssuredTek remains the owners of the Software and Documentation at all times.
PLEASE READ THIS EULA CAREFULLY BEFORE YOU INSTALL, COPY, DOWNLOAD OR USE THE SOFTWARE. BY INSTALLING, COPYING, DOWNLOADING OR USING THE SOFTWARE, YOU, ON BEHALF OF YOURSELF AND/OR THE ORGANIZATION YOU REPRESENT, AGREE TO BE BOUND BY ALL TERMS AND CONDITIONS OF THIS EULA INCLUDING ALL TERMS AND CONDITIONS INCORPORATED HEREIN BY REFERENCE.
IF YOU DO NOT AGREE WITH THE FOLLOWING TERMS, THEN YOU MUST IMMEDIATELY STOP USING THE SOFTWARE.

For more information, please check out the end user license agreement section of our company website at <a target="_blank" href="https://www.assuredtek.com">https://www.assuredtek.com</a>.

## Privacy
This Privacy Policy describes how and when AssuredTek, Inc. collects, uses, and shares information when you contact us or sign up for the user group through our site. This Privacy Policy does not apply to the practices of third parties that AssuredTek, Inc. does not own or control or any third party services you can access through those parties. AssuredTek, Inc. doesn’t ask you for your personal information unless we need it, and it doesn’t share or store your information except to comply with the law or to protect AssuredTek, Inc. 

For more information, please check out our privacy policy section of our company website at <a target="_blank" rel='noopener noreferrer' href="https://assuredtek.com/privacy-policy">https://assuredtek.com/privacy-policy</a>.

## Usage

For additional terms and conditions, please check out our terms of use section of our company website at <a target="_blank" href="https://www.assuredtek.com">https://www.assuredtek.com</a>.
`}
            </ReactMarkdown>
          </Box>
        </Box>
      </Modal>
    </StartupPageStyle>
  );
};
