// import { makeStyles } from '@mui/styles';
// import { useEffect, useRef } from 'react';
// import LogoSVG from '../../../assets/images/logo/Vizla-icon-light.svg';

// export const LogoPerspective = () => {
//   const target: any = useRef();

//   const useStyles = makeStyles({
//     card: {
//       backgroundImage: `url(${LogoSVG})`,
//       backgroundSize: 'cover',
//       //   boxShadow: '0 0 140px 10px rgba(0,0,0,.5)',
//       position: 'relative',
//       height: 200,
//       width: 345,
//       overflow: 'hidden', // Try removing this to see how the sheen works!
//       '--screenX': 0 /* Set these with JavaScript */,
//       '--screenY': 0,
//     },
//     'card::after': {
//       content: '',
//       position: 'absolute',
//       top: -400,
//       right: -400,
//       bottom: -400,
//       left: -400,
//       background:
//         'linear-gradient(217deg, rgba(255,255,255,0), rgba(255,255,255,0) 35%, rgba(255,255,255,0.25) 45%, rgba(255,255,255,.25) 50%, rgba(255,255,255,0) 60%, rgba(255,255,255,0) 100%)',
//       transform: 'translateX(var(--screenX)) translateY(var(--screenY))',
//     },
//     demo: {
//       //   backgroundColor: 'hsl(207, 9%, 19%)',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       height: '35vh',
//       width: '35vw',
//     },
//     perspectiveContainer: {
//       perspective: 900,
//     },
//   });

//   function handleMouseMove(event: any) {
//     const height = window.innerHeight;
//     const width = window.innerWidth;
//     // Creates angles of (-20, -20) (left, bottom) and (20, 20) (right, top)
//     const yAxisDegree = (event.pageX / width) * 40 - 20;
//     const xAxisDegree = (event.pageY / height) * -1 * 40 + 20;
//     target.current.style.transform = `rotateY(${yAxisDegree}deg) rotateX(${xAxisDegree}deg)`;
//     // Set the sheen position
//     setSheenPosition(event.pageX / width, event.pageY / width);
//   }

//   function setSheenPosition(xRatio: any, yRatio: any) {
//     // This creates a "distance" up to 200px each direction to offset the sheen
//     const xOffset = 1 - (xRatio - 0.5) * 400;
//     const yOffset = 1 - (yRatio - 0.5) * 400;
//     target.current.style.setProperty('--screenX', `${xOffset}px`);
//     target.current.style.setProperty('--screenY', `${yOffset}px`);
//   }

//   useEffect(() => {
//     document.onmousemove = handleMouseMove;
//   }, []);

//   const classes = useStyles();

//   return (
//     <div>
//       <div className={classes.demo}>
//         <div className={classes.perspectiveContainer}>
//           <div ref={target} className={classes.card}></div>
//         </div>
//       </div>
//     </div>
//   );
// };

import { makeStyles } from 'tss-react/mui';
import LogoSVG from '../../../assets/images/logo/Vizla-icon-light.svg';

const useStyles = makeStyles()((theme) => ({
  card: {
    backgroundImage: `url(${LogoSVG})`,
    backgroundSize: 'cover',
    //   boxShadow: '0 0 140px 10px rgba(0,0,0,.5)',
    position: 'relative',
    height: 200,
    width: 345,
    overflow: 'hidden', // Try removing this to see how the sheen works!
    '--screenX': 0 /* Set these with JavaScript */,
    '--screenY': 0,
  },
  'card::after': {
    content: '""',
    position: 'absolute',
    top: -400,
    right: -400,
    bottom: -400,
    left: -400,
    background:
      'linear-gradient(217deg, rgba(255,255,255,0), rgba(255,255,255,0) 35%, rgba(255,255,255,0.25) 45%, rgba(255,255,255,.25) 50%, rgba(255,255,255,0) 60%, rgba(255,255,255,0) 100%)',
    transform: 'translateX(var(--screenX)) translateY(var(--screenY))',
  },
  demo: {
    //   backgroundColor: 'hsl(207, 9%, 19%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '35vh',
    width: '35vw',
  },
  perspectiveContainer: {
    perspective: 800,
  },
}));

export const LogoPerspective = () =>
  // props: Props & Omit<MuiButtonProps, keyof Props>
  {
    // const { className, color, ...other } = props;
    // const target: any = useRef();
    //   const [color, setColor] = useState<StyleProps>('red');

    const { classes, cx } = useStyles();
    //   const { color, ...other } = props;
    //   const classes = useStyles(props);
    return (
      <div>
        <div className={cx(classes.demo)}>
          <div className={cx(classes.perspectiveContainer)}>
            <div className={cx(classes.card)}></div>
          </div>
        </div>
      </div>
    );
  };
