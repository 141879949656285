import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Container, Divider, Stack, TextareaAutosize, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { API } from 'aws-amplify';
import ReactJson from 'react-json-view';

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function PostmanPage() {
    const [jsonText, setJsonText] = useState("");
    const [apiResp, setApiResp] = useState("");
    const [apiRespJson, setApiRespJson] = useState({});

    const apiPost = (funcName: string) => {
        if (jsonText == "") { console.log('nothing to send'); return; }

        console.log("🚀 ~ file: Postman.tsx:30 ~ apiPost ~ jsonText", jsonText)
        const body = JSON.parse(jsonText);
        API.post(`${funcName}`, `/${funcName}`, { body }).then((resp) => {
            console.log("🚀 ~ file: Postman.tsx:27 ~ API.post ~ resp", resp);
            setApiResp(JSON.stringify(resp));
            setApiRespJson(resp);
        });
    };
    const apiGet = (funcName: string) => {
        //const body = JSON.parse(jsonText);
        API.get(`${funcName}`, `/${funcName}`, {}).then((resp) => {
            console.log("🚀 ~ file: Postman.tsx:27 ~ API.get ~ resp", resp);
            setApiResp(JSON.stringify(resp));
            setApiRespJson(resp);
        });
    };

    return (
        <>
            <Stack sx={{ textAlign: 'center', alignItems: 'center', m: 0, p: 0, backgroundColor: 'red' }}>
                <Typography variant="h5" sx={{ color: 'white' }}>This is our development test page.  NOT TO BE PUBLISHED IN PROD.</Typography>
            </Stack>
            <Divider sx={{ mb: 2 }} />
            <Stack direction="row">
                <Stack direction="column" >
                    <Container>
                        <Typography sx={{ color: 'text.secondary' }}>GETs</Typography>
                        <Button
                            size='large'
                            variant='contained'
                            onClick={() => apiGet("playbooks")}>
                            GET /PLAYBOOKS
                        </Button>
                        <Container sx={{ mb: 2 }} />
                        <Typography sx={{ color: 'text.secondary' }}>POSTs</Typography>
                        <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Enter JSON POST body here..."
                            style={{ width: 250, height: 250 }}
                            value={jsonText}
                            onChange={(event) => setJsonText(event.target.value)}
                        />




                    </Container>
                    <Container>
                        <Button
                            size='large'
                            variant='contained'
                            onClick={() => apiPost("playbooks")}>
                            POST TO /PLAYBOOKS
                        </Button>


                    </Container>

                </Stack>
                {/* <ContentStyle sx={{ textAlign: 'top', alignItems: 'start',  }}> */}


                {/* </ContentStyle> */}
                <Container>
                    <Typography sx={{ color: 'text.secondary' }}>Response as Object</Typography>
                    <ReactJson src={apiRespJson} />
                    <Divider sx={{ mt: 5 }} />
                    <Divider />
                    <Typography sx={{ color: 'text.secondary' }}>Response As String</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>{apiResp}</Typography>
                </Container>
            </Stack>

        </>

    );
}
