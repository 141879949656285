import { API } from 'aws-amplify';

/**
 * Posts a request to the payments API and redirects to the returned URL.
 * @param resourcePath API resource path 
 * @param bodyJson POST body in JSON format
 */
export const apiPostAndRedirect = async (resourcePath: string, bodyJson: any) => {
    const result = await API.post('payments', `/payments/${resourcePath}`, { body: bodyJson });
    window.location.href = result.url;
}