import { useContext, useRef, useState } from 'react';
// @mui
import { Avatar, IconButton, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { selectUser } from '../../api/user/userSlice';
import { selectCompany } from '../../api/company/companySlice';
import { useAppSelector } from '../../app/hooks';
import Avatarka from '../../assets/images/avatar_default.jpg';
import { AccountContext } from '../Authentication/Accounts';
import MenuPopover from '../Menu/MenuPopover';
import { apiPostAndRedirect } from '../../utils/api';
// import MenuPopover from '../Menu/MenuPopover';
// components
// import MenuPopover from '../../components/MenuPopover';
// mocks_
// import account from '../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '#',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { logout }: any = useContext(AccountContext);
  const user = useAppSelector(selectUser);
  const company = useAppSelector(selectCompany);

  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const logOut = () => {
    logout();
    setOpen(null);
  };

  return (
    <>
      <Typography variant='body2'>{user.email}</Typography>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open
            ? {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }
            : {}),
        }}>
        <Avatar src={Avatarka} alt='photoURL' />
      </IconButton>
      {/* <Avatar src={account.photoURL} alt="photoURL" /> */}

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}>
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle2' noWrap>
            {user.first_name} {user.last_name}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} /> */}
        {/* <MenuItem onClick={() => apiPostAndRedirect('create-portal-session', { customer_id: company.payment_customer_id })}
          sx={{ m: 1 }}>
          Manage Subscriptions
        </MenuItem> */}
        <MenuItem onClick={logOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
