// @mui
import {
    Button,
    Container,
    Stack,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { selectUser } from '../../api/user/userSlice';
import { useAppSelector } from '../../app/hooks';
import { selectCompany } from '../../api/company/companySlice';
import { apiPostAndRedirect } from '../../utils/api';

export default function CheckoutSuccessPage() {
    const navigate = useNavigate();
    const user = useAppSelector(selectUser);
    const company = useAppSelector(selectCompany);

    return (
        <>
            <Container>
                <Typography variant='h4' sx={{ mb: 5 }}>
                    Your order is complete!
                </Typography>

                <Container sx={{ p: 4 }}>
                    <Stack direction='row' spacing={1}>
                        <Button variant='contained' onClick={() => navigate(`/${user['custom:domain_name']}/marketplace`)}>
                            Return to Marketplace
                        </Button>
                        <Button variant='outlined' onClick={() => apiPostAndRedirect('create-portal-session', { customer_id: company.payment_customer_id })}>
                            Manage Your Subscriptions
                        </Button>
                    </Stack>
                </Container>
            </Container>
        </>
    );
}  