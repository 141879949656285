import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { API } from 'aws-amplify';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { selectCompany } from '../../api/company/companySlice';
import { selectUser } from '../../api/user/userSlice';
import { useAppSelector } from '../../app/hooks';
import { StyledLoadingButton } from '../ContainerStyles/ButtonStyles/LoadingButtonStyle';
// ----------------------------------------------------------------------

export default function GenerateLicenseFile({ setIsUpdated, licenses }: any) {
  const company = useAppSelector(selectCompany);
  const user = useAppSelector(selectUser);

  const [open, setOpen] = useState(false);
  const [mac, setMac] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleClickOpen = () => {
    if (licenses.some((license: any) => license.mac === mac)) {
      setMessage('Are you sure you want to override existing license file?');
    } else {
      setMessage('Are you sure you want to create new license file?');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleMacChange = (mac: string) => {
    setMac(mac);
  };
  const handleSubmit = () => {
    API.post('onboaringApi', `/mac/${mac}`, {
      body: { ...company, email: user.email },
    })
      .then((resp) => {
        setIsUpdated(true);
        toast.success('License generated successfully');
        handleClose();
      })
      .catch((e) => toast.error(e.message));
  };
  const getPlaybooks = () => {
    API.get('playbooks', `/playbooks`, {
    })
      .then((resp) => {
        console.log("🚀 ~ file: GenerateLicenseFile.tsx:62 ~ .then ~ resp", resp)
        setIsUpdated(true);
        toast.success('Received Data');
        handleClose();
      })
      .catch((e) => toast.error(e.message));
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New License</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Proceed</Button>
        </DialogActions>
      </Dialog>
      <TextField
        fullWidth
        autoComplete='mac'
        type='text'
        label='MAC Address'
        onChange={(e) => handleMacChange(e.target.value)}
      />

      <Box sx={{ p: 1 }} />

      <StyledLoadingButton
        fullWidth
        color='blue'
        size='large'
        variant='contained'
        onClick={handleClickOpen}
        loading={loading}>
        Generate License File
      </StyledLoadingButton>
      {/* <StyledLoadingButton
        fullWidth
        color='blue'
        size='large'
        variant='contained'
        onClick={getPlaybooks}
        loading={loading}>
        Get Playbooks
      </StyledLoadingButton> */}
    </>
  );
}
