import { Amplify, API } from 'aws-amplify';
import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { saveToken } from '../api/auth/authSlice';
import { saveCompany } from '../api/company/companySlice';
import { saveUser } from '../api/user/userSlice';
import { useAppDispatch } from '../app/hooks';
import awsExports from '../aws-exports.js';
import { AccountContext } from '../components/Authentication/Accounts';
import DashboardLayout from '../components/Layout/DashboardLayout';
import NotFound from '../components/NotFound/NotFound';
import UnderMaintenance from '../components/UnderMaintenance/UnderMaintenance';
import { DashboardPage } from './Dashboard/DashboardPage';
import { LoginPage } from './Login/LoginPage';
import CheckoutPage from './Marketplace/CheckoutPage';
import CheckoutSuccessPage from './Marketplace/CheckoutSuccessPage';
import MarketplacePage from './Marketplace/MarketplacePage';
import MyPlaybooksPage from './Marketplace/MyPlaybooksPage';
import PostmanPage from './Postman/Postman';
import { RegisterPage } from './Register/RegisterPage';

Amplify.configure(awsExports);

export const ColorModeContext = createContext({ toggleColorMode: () => { } });

export const App = () => {
  const [domain, setDomain] = useState('');
  const { getSession }: any = useContext(AccountContext);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    getSession()
      .then((session: any) => {
        dispatch(saveToken(session.idToken.jwtToken));
        dispatch(saveUser(session.idToken.payload));
        const userDomain = session.idToken.payload['custom:domain_name'];
        API.get('onboaringApi', `/company/${userDomain}`, {}).then(
          (response) => {
            if (!!response.Item.domain_name) {
              dispatch(saveCompany(response.Item));
              setDomain(response.Item.domain_name);
            }
          }
        );
        //navigate('/' + userDomain);
      })
      .catch((e: any) => {
        navigate('/signin');
      });
  }, [domain]);

  return (
    <>
      <ToastContainer theme='dark' />
      <Routes>
        <Route>
          <Route path={'/'} element={<DashboardLayout domain={domain} />}>
            <Route path={'/:domain'}>
              <Route path='/:domain/marketplace' element={<MarketplacePage />} />
              <Route path='/:domain/checkout' element={<CheckoutPage />} />
              <Route path='/:domain/playbooks' element={<MyPlaybooksPage />} />
              <Route path='/:domain/postman' element={<PostmanPage />} />
              <Route path='/:domain/chatter' element={<UnderMaintenance />} />
              <Route path='/:domain/users' element={<UnderMaintenance />} />
              <Route path='/:domain/billing' element={<UnderMaintenance />} />
              <Route path='/:domain/account' element={<UnderMaintenance />} />
              <Route path='/:domain/tos' element={<UnderMaintenance />} />
              <Route path='/:domain/checkout-success' element={<CheckoutSuccessPage />} />
              <Route path={'/:domain'} element={<DashboardPage />} />
            </Route>

            <Route path='404' element={<NotFound />} />
            <Route path='/*' element={<Navigate to='/404' />} />
          </Route>
        </Route>
        <Route path='/signin' element={<LoginPage />} />
        <Route path='/signup' element={<RegisterPage />} />
      </Routes>
    </>
  );
};
