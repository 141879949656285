import PropTypes from 'prop-types';
// @mui
import { Box, Button, Card, Chip, Link, Stack, Typography } from '@mui/material';
import numeral from 'numeral';
import { useNavigate } from 'react-router';
import { savePlaybook } from '../../api/marketplace/playbookSlice';
import { selectUser } from '../../api/user/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectCompany } from '../../api/company/companySlice';
import { apiPostAndRedirect } from '../../utils/api';

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
  cart: PropTypes.array,
  setCart: PropTypes.func,
};

function result(format: any, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

let fCurrency = (number: number) => {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
};

export default function ShopProductCard({ product, cart, setCart }: any) {
  const {
    meta: { name,
      description },
    price: { price, currency, payment_price_id },
  } = product;
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const company = useAppSelector(selectCompany);

  return (
    <Card sx={{ height: '100%', display: 'flex' }}>
      {/* <Box sx={{ pt: '100%', position: 'relative' }}> */}
      {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )} */}
      {/* <StyledProductImg alt={name} src={cover} /> */}
      {/* </Box> */}

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color='inherit' underline='hover'>
          <Typography variant='h6' noWrap>
            {name}
          </Typography>
          <Typography variant='subtitle2'>{description}</Typography>
        </Link>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'>
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant='subtitle1'>
            <Typography
              component='span'
              variant='body1'
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}>
              {/* {priceSale && fCurrency(priceSale)} */}
            </Typography>
            &nbsp;
            {fCurrency(price)} {currency}
          </Typography>
        </Stack>
        <Stack direction='row' spacing={1}>
          {/* <Button
            variant='contained'
            onClick={() => {
              dispatch(savePlaybook([...cart, product]));
              apiPostAndRedirect('create-checkout-session', 
                {
                  customer_id: company.payment_customer_id,
                  price_list: [{ price: payment_price_id, quantity: 1 } ]
                });
            }}>
            Buy now
          </Button> */}
          <Chip label="Network" variant="outlined" />
          <Button
            variant='outlined'
            onClick={() => setCart([...cart, product])}>
            Add to Cart
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
