import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  cart: PropTypes.array,
  setCart: PropTypes.func,
};

export default function ProductList({
  products,
  cart,
  setCart,
  ...other
}: any) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product: any) => (
        <Grid key={product.meta.uid} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product} cart={cart} setCart={setCart} />
        </Grid>
      ))}
    </Grid>
  );
}
