import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainCardStyle = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxWidth: '70em',
    width: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '70em',
    display: 'flex',
    width: '100%',
  },
  backgroundColor: 'lightblue',
  borderRadius: '30px',
  boxShadow: '0px 2px 20px 3px rgb(0 0 0 / 20%)',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(2, 2, 2, 2),
}));
